import {
  GithubAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut
} from 'firebase/auth';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer
} from 'react';

import PropTypes from 'prop-types';
// config
import firebaseConfig from 'configs/FirebaseConfig';
import { initializeApp } from 'firebase/app';
import { message } from 'antd';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  isTempPassword: false,
  user: null
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
      isTempPassword: action.payload.isTempPassword,
      role: action.payload.role,
      accountId: action.payload.accountId
    };
  }
  if (action.type === 'LOGIN') {
    return {
      isInitialized: true,
      role: action.payload.role,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
      isTempPassword: action.payload.isTempPassword,
      accountId: action.payload.accountId
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(firebaseConfig);

const AUTH = getAuth(firebaseApp);


const GOOGLE_PROVIDER = new GoogleAuthProvider();

const GITHUB_PROVIDER = new GithubAuthProvider();

const TWITTER_PROVIDER = new TwitterAuthProvider();

AuthProvider.propTypes = {
  children: PropTypes.node
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async user => {
        if (user) {
          const jsonToken = JSON.parse(atob(user.accessToken.split('.')[1]));
          localStorage.setItem('auth_token', user.accessToken);
          dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: true,
              user: {
                ...user
              },
              role: jsonToken.roles,
              isTempPassword: jsonToken.isTempPassword,
              accountId: jsonToken.accountId
            }
          });
        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const reponseData = await signInWithEmailAndPassword(AUTH, email, password);

    if (reponseData && reponseData.user) {
      const token = reponseData.user.accessToken;
      const jsonToken = JSON.parse(atob(token.split('.')[1]));
      localStorage.setItem('auth_token', token);
      dispatch({
        type: 'LOGIN',
        payload: {
          isAuthenticated: true,
          user: {
            ...reponseData.user
          },
          role: jsonToken.roles,
          isTempPassword: jsonToken.isTempPassword,
          accountId: jsonToken.accountId
        }
      });
    }
  }, []);

  const loginWithGoogle = useCallback(() => {
    signInWithPopup(AUTH, GOOGLE_PROVIDER);
  }, []);

  const loginWithGithub = useCallback(() => {
    signInWithPopup(AUTH, GITHUB_PROVIDER);
  }, []);

  const loginWithTwitter = useCallback(() => {
    signInWithPopup(AUTH, TWITTER_PROVIDER);
  }, []);

  // REGISTER

  // LOGOUT
  const logout = useCallback(() => {
    signOut(AUTH);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'firebase',
      role: state.role,
      isTempPassword: state.isTempPassword,
      login,
      loginWithGoogle,
      loginWithGithub,
      loginWithTwitter,
      accountId: state.accountId,
      logout
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      loginWithGithub,
      loginWithGoogle,
      loginWithTwitter,
      state.role,
      state.isTempPassword,
      state.accountId,
      logout
    ]
  );
  console.log(memoizedValue);
  if (state && state.role != undefined && !state?.role?.includes('ROLE_SYSTEM_ADMIN')) {
    logout();
    message.error("Invalid")
  }

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
