import { UPDATE_PLAN, UPADTE_ALL_PLAN, NEW_PLAN } from '../actions/PlanActions';

const initialState = {
  formData: {},
  plans: [],
  mode: 'ADD'
};

const planReducer = (state = initialState, action) => {
  if (action.type === UPDATE_PLAN) {
    return Object.assign({}, state, {
      formData: action.payload
    });
  }
  if (action.type === UPADTE_ALL_PLAN) {
    return Object.assign({}, state, {
      plans: action.payload
    });
  }
  if (action.type === NEW_PLAN) {
    return Object.assign({}, state, {
      formData: {}
    });
  }
  return state;
};

export default planReducer;
