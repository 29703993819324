export const UPDATE_PLAN = 'UPDATE_PLAN';
export const ALL_PLANS = 'ALL_PLANS';
export const UPADTE_ALL_PLAN = 'UPADTE_ALL_PLAN';
export const NEW_PLAN = 'NEW_PLAN';
export const DELETE_PLAN = 'DELETE_PLAN';
export const GET_PLAN = 'GET_PLAN';

export function getAllPlans() {
  return {
    type: ALL_PLANS
  };
}

export function newPlan() {
  return {
    type: NEW_PLAN
  };
}

export function getPlan(action) {
  return {
    type: GET_PLAN,
    payload: action
  };
}

export function updatePlan(action) {
  return {
    type: UPDATE_PLAN,
    payload: action
  };
}

export function deletePlan(action) {
  return {
    type: DELETE_PLAN,
    payload: action
  };
}
