import axiosInstance from 'utils/axios';

const baseUrl = '/convey_ai/v1/advertisers';

const advertiserService = {};

advertiserService.getByAccount = function (accountID) {
  return axiosInstance.get(`${baseUrl}/${accountID}`);
};

advertiserService.getAll = function () {
  return axiosInstance.get(`${baseUrl}/`);
};

advertiserService.get = function (accountID, advertiserID) {
  return axiosInstance.get(`${baseUrl}/${accountID}/${advertiserID}`);
};

advertiserService.deleteAdvertiser = function (advertiserID, accountID) {
  return axiosInstance.delete(`${baseUrl}/${accountID}/${advertiserID}`);
};

advertiserService.getAdvertiser = function (accountId, id) {
  return axiosInstance.get(`${baseUrl}/${accountId}/${id}`);
};

advertiserService.loadJobs = function () {
  return axiosInstance.get(`/convey_ai/v1/jobs/`);
};

advertiserService.save = function (data) {
  if (data.id) {
    return axiosInstance.put(`${baseUrl}/${data.id}`, data);
  } else {
    return axiosInstance.post(`${baseUrl}/`, data);
  }
};

export default advertiserService;
