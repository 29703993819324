import axiosInstance from 'utils/axios';

const baseUrl = '/convey_ai/v1/campaign';

const campService = {};

campService.saveCamp = function (data) {
  if (data.id) {
    return axiosInstance.put(`${baseUrl}/${data.id}`, data);
  } else {
    return axiosInstance.post(`${baseUrl}/save`, data);
  }
};
campService.pauseCampagin = function (data) {
  return axiosInstance.put(`${baseUrl}/${data.id}/pauseCampaign`, data);
};

campService.resumeCampaign = function (data) {
  return axiosInstance.put(`${baseUrl}/${data.id}/resumeCampaign`, data);
};

campService.deleteSupplier = function (data) {
  return axiosInstance.delete(
    `${baseUrl}/${data.accountID}/${data.campId}/deleteSupplier/${data.id}`
  );
};

campService.updateSupplier = function (data) {
  return axiosInstance.put(`${baseUrl}/${data.accountID}/updateSupplier`, data);
};

campService.getAllCamps = function (data) {
  return axiosInstance.get(`${baseUrl}/${data}`);
};

campService.deleteCamps = function (data) {
  return axiosInstance.delete(`${baseUrl}/${data.accountId}/${data.id}`);
};

campService.createSupplier = function (data) {
  return axiosInstance.post(
    `${baseUrl}/${data.accountID}/createSupplier`,
    data
  );
};
campService.getCampById = function (data) {
  return axiosInstance.get(`${baseUrl}/${data.accountId}/${data.id}`);
};

export default campService;
