import { values } from 'lodash';
import axiosInstance from 'utils/axios';

const baseUrl = '/convey_ai/api/user';

const userService = {};

userService.changePassword = function (data) {
  return axiosInstance.post(`${baseUrl}/changePassword`, data);
};

userService.getAllUsers = function () {
  return axiosInstance.get(`${baseUrl}/`);
};

userService.getUserByAccount = function (id) {
  return axiosInstance.get(`${baseUrl}/account/${id}`);
};

userService.getUser = function (id) {
  return axiosInstance.get(`${baseUrl}/${id}`);
};

userService.saveUser = function (data) {
  if (data.id) {
    return axiosInstance.put(`${baseUrl}/update/${data.id}`, data);
  } else {
    return axiosInstance.post(`${baseUrl}/save`, data);
  }
};

userService.deleteUser = function (id) {
  return axiosInstance.delete(`${baseUrl}/${id}`);
};

export default userService;
