import { combineReducers } from 'redux';
import theme from './slices/themeSlice';
import auth from './slices/authSlice';
//import account from './slices/accountSlice';
import {
  accountReducer,
  userReducer,
  advertiserReducer,
  publisherReducer,
  campReducer,
  formReducer,
  planReducer
} from 'redux/reducers/index';

const rootReducer = asyncReducers => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    account: accountReducer,
    users: userReducer,
    advertiser: advertiserReducer,
    publisher: publisherReducer,
    camp: campReducer,
    forms: formReducer,
    plans: planReducer,
    ...asyncReducers
  });
  return combinedReducer(state, action);
};

export default rootReducer;
