import {
  ALL_CAMPS,
  DELETE_CAMP,
  UPDATE_ALL_CAMPS,
  GET_CAMP,
  UPDATE_CAMP
} from 'redux/actions/CampActions';
import campService from 'services/campService';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { message } from 'antd';
import dayjs from 'dayjs';
function* getAllCamps(action) {
  //const projects = yield projectService.getAllProjects();
  const { data } = yield campService.getAllCamps(action.payload.accountId);
  const finalData = data;

  yield put({
    type: UPDATE_ALL_CAMPS,
    payload: finalData
  });
}

function* deleteCampAction(action) {
  const data = yield campService.deleteCamps(action.payload);
  if (data.status === 204) {
    message.success('Campaign deleted successfully');
    const { finalData } = yield campService.getAllCamps(
      action.payload.accountId
    );
    yield put({
      type: UPDATE_ALL_CAMPS,
      payload: finalData
    });
  }
}

function* getCampAction(action) {
  const { data } = yield campService.getCampById(action.payload);
  if (data) {
    const tempData = data;
    tempData['accountID'] = action.payload.accountId;
    if (tempData.startDate && tempData.endDate) {
      tempData['startDate'] = [
        dayjs(tempData.startDate),
        dayjs(tempData.endDate)
      ];
    }
  }
  yield put({
    type: UPDATE_CAMP,
    payload: data
  });
}

export function* fetchAllCamps() {
  yield takeLatest(ALL_CAMPS, getAllCamps);
}

export function* deleteCamp() {
  yield takeLatest(DELETE_CAMP, deleteCampAction);
}

export function* getCamp() {
  yield takeLatest(GET_CAMP, getCampAction);
}
