import axiosInstance from 'utils/axios';

const baseUrl = '/convey_ai/v1/plans';

const planService = {};

planService.getPost = function (params) {
  return fetch({
    url: '/posts/1',
    method: 'get',
    params
  });
};

planService.getAllPlans = function () {
  return axiosInstance.get(`${baseUrl}/`);
};

planService.deletePlan = function (id) {
  return axiosInstance.delete(`${baseUrl}/${id}`);
};

planService.getPlan = function (id) {
  return axiosInstance.get(`${baseUrl}/${id}`);
};

planService.save = function (data) {
  if (data.id) {
    return axiosInstance.put(`${baseUrl}/${data.id}`, data);
  } else {
    return axiosInstance.post(`${baseUrl}/`, data);
  }
};

export default planService;
