import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store from './store';
import history from './history';
import Layouts from './layouts';
import { THEME_CONFIG } from './configs/AppConfig';
import './lang';
import { AuthProvider } from './auth/FirebaseContext';

const oidcConfig = {
  authority: 'http://192.168.1.57:8080/realms/Coney_ai',
  client_id: 'convey_ai',
  redirect_uri: 'http://localhost:3000/app/dashboards/default'
  // ...
};

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`
};

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Provider store={store}>
          <BrowserRouter history={history}>
            <ThemeSwitcherProvider
              themeMap={themes}
              defaultTheme={THEME_CONFIG.currentTheme}
              insertionPoint="styles-insertion-point"
            >
              <Layouts />
            </ThemeSwitcherProvider>
          </BrowserRouter>
        </Provider>
      </AuthProvider>
    </div>
  );
}

export default App;
