import axios from 'axios';
import { User } from 'oidc-client-ts';
// config

// ----------------------------------------------------------------------

function getUser() {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:http://192.168.1.57:8080/realms/Coney_ai:convey_ai`
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

const axiosInstance = axios.create({ baseURL: 'https://stageapi.convey.ai' });
axiosInstance.interceptors.request.use(headerConfig => {
  const token = localStorage.getItem('auth_token');
  if (token) {
    headerConfig.headers.Authorization = `Bearer ${token}`;
  }
  return headerConfig;
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      //localStorage.removeItem('currentUser');
      localStorage.removeItem('auth_token');
      //window.location.href = '/login';
    } else if (error.response == undefined) {
      //localStorage.removeItem('currentUser');
      window.location.reload();
    } else if (error.response.status === 500) {
      // message.error(error.response.data.message);
      return Promise.reject(error);
    } else if (error.response.status === 409) {
      //message.error(error.response.data.message);
      return Promise.reject(error);
    } else if (error.response.status === 403) {
      //message.error("Don't have permissions to perform this operation");
      // window.location.href = '/easydev/log_in';
      return Promise.reject(error);
    } else if (error.response.status === 404) {
      // message.error(error.response.data.message);
      return Promise.reject(error);
    } else if (error.response.status === 400) {
      if (
        error.response.data.errors &&
        Array.isArray(error.response.data.errors)
      ) {
        error.response.data.errors.forEach(value => {
          // message.error(value.defaultMessage);
        });
      } else {
        //message.error(error.response.data.message);
      }

      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);
export default axiosInstance;
