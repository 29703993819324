export const UPADTE_ACCOUNT = 'UPADTE_ACCOUNT';
export const ALL_ACCOUNTS = 'ALL_ACCOUNTS';
export const UPADTE_ALL_ACCOUNT = 'UPADTE_ALL_ACCOUNT';
export const NEW_ACCOUNT = 'NEW_ACCOUNT';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';

export function getAllAccounts() {
  return {
    type: ALL_ACCOUNTS
  };
}

export function newAccount() {
  return {
    type: NEW_ACCOUNT
  };
}

export function updateAccount(action) {
  return {
    type: UPADTE_ACCOUNT,
    payload: action
  };
}

export function deleteAccount(action) {
  return {
    type: DELETE_ACCOUNT,
    payload: action
  };
}
