import React from 'react';
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';

export const publicRoutes = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() => import('views/auth-views/authentication/login'))
  },
  {
    key: 'login-1',
    path: `${AUTH_PREFIX_PATH}/login-1`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/login-1')
    )
  },
  {
    key: 'login-2',
    path: `${AUTH_PREFIX_PATH}/login-2`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/login-2')
    )
  },
  {
    key: 'register-1',
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/register-1')
    )
  },
  {
    key: 'register-2',
    path: `${AUTH_PREFIX_PATH}/register-2`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/register-2')
    )
  },
  {
    key: 'forgot-password',
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/forgot-password')
    )
  },
  {
    key: 'change-password',
    path: `${AUTH_PREFIX_PATH}/change-password`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/forgot-password')
    )
  },
  {
    key: 'error-page-1',
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import('views/auth-views/errors/error-page-1'))
  },
  {
    key: 'error-page-2',
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import('views/auth-views/errors/error-page-2'))
  },
  {
    key: 'forms-slug',
    path: `${AUTH_PREFIX_PATH}/forms/:id`,
    component: React.lazy(() => import('views/auth-views/forms'))
  }
];

export const protectedRoutes = {
  ROLE_SYSTEM_ADMIN: [
    {
      key: 'sidenav.settings.accounts',
      path: `${APP_PREFIX_PATH}/settings/accounts`,
      component: React.lazy(() =>
        import('views/app-views/accounts/accouts-list')
      )
    },
    {
      key: 'sidenav.settings.accounts',
      path: `${APP_PREFIX_PATH}/settings/accounts/add`,
      component: React.lazy(() =>
        import('views/app-views/accounts/accounts-add')
      )
    },
    {
      key: 'sidenav.settings.accounts',
      path: `${APP_PREFIX_PATH}/settings/accounts/edit/:id`,
      component: React.lazy(() =>
        import('views/app-views/accounts/accounts-add')
      )
    },
    {
      key: 'sidenav.settings.plans',
      path: `${APP_PREFIX_PATH}/settings/plans`,
      component: React.lazy(() => import('views/app-views/plans/plans-list'))
    },
    {
      key: 'sidenav.settings.accounts',
      path: `${APP_PREFIX_PATH}/settings/plans/add`,
      component: React.lazy(() => import('views/app-views/plans/plans-add'))
    },
    {
      key: 'sidenav.settings.accounts',
      path: `${APP_PREFIX_PATH}/settings/plans/edit/:id`,
      component: React.lazy(() => import('views/app-views/plans/plans-add'))
    }
  ]
};
