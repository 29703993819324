import {
  ALL_PLANS,
  DELETE_PLAN,
  UPADTE_ALL_PLAN,
  GET_PLAN,
  UPDATE_PLAN
} from 'redux/actions/PlanActions';
import planService from 'services/planService';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { message } from 'antd';
import dayjs from 'dayjs';
function* getAllPlansAction() {
  //const projects = yield projectService.getAllProjects();
  const { data } = yield planService.getAllPlans();

  yield put({
    type: UPADTE_ALL_PLAN,
    payload: data
  });
}

function* deletePlanAction(action) {
  const tempData = yield planService.deletePlan(action.payload);
  if (tempData.status === 204) {
    message.success('Plan delete successfully');
    const { data } = yield planService.getAllPlans();
    yield put({
      type: UPADTE_ALL_PLAN,
      payload: data
    });
  }
}

function* getPlanAction(payload) {
  //const projects = yield projectService.getAllProjects();
  const { data } = yield planService.getPlan(payload.payload.id);
  if (data) {
    data['plan'] = [dayjs(data.planStartDate), dayjs(data.planEndDate)];
  }
  yield put({
    type: UPDATE_PLAN,
    payload: data
  });
}

export function* fetchAllPlans() {
  yield takeLatest(ALL_PLANS, getAllPlansAction);
}

export function* deletePlan() {
  yield takeLatest(DELETE_PLAN, deletePlanAction);
}

export function* getPlan() {
  yield takeLatest(GET_PLAN, getPlanAction);
}
